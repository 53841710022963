html, body {
  width:  100%;
  height: 100%;
  margin: 0;
  touch-action: none;
  background-color: #3b3b3b;
}

canvas {
  image-rendering: pixelated;  // Use nearest-neighbor to keep all pixel art crisp.
}


.canvasBase {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.canvasWrapper {
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
}

.canvasBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e8e6e6;
  background-image:
          linear-gradient(to right, grey 1px, transparent 1px),
          linear-gradient(to bottom, grey 1px, transparent 1px);
  background-size: 48px 48px;
  image-rendering: pixelated;
}


.entityWrapper:hover {
  outline: 3px solid yellow;
}

.entityContainer {
  background: rgba(0,0,0,0);
  position: absolute;
  pointer-events: none;
}

.entityMoveTracker {
  position: absolute;
  background: rgba(59, 59, 59, 0.7);
  pointer-events: none;
  text-align: center;
  line-height: 40px; // Hack to "center" text vertically.
  color: whitesmoke;
}

.entityNamePlate {
  position: absolute;
  pointer-events: none;
  background: rgba(204, 204, 204, 0.7);
  transform: translate(-50%, -50%);
  padding-left: 4px;
  padding-right: 4px;
}
