
.ytPlayerIcon {
  width: 56px;
  margin-top: 15px;
}

.ytPlayerIconInner {
  color: #d03434;
}

.ytPlayerIconInner.visible {
  color: #7f1e1e;
}

.ytPlayerWrapper {
  display: none;
  position: fixed;
  left: 50%;
  bottom: 75px;
  transform: translateX(-50%);
}

.ytPlayerWrapper {
  background: rgba(245, 245, 245, 0.79);
  border: 1px solid black;
}

.ytPlayerWrapper.visible {
  display: block;
}


.ytPlayerHostToolbar {
  display: flex;
  flex-grow: 1;
}

.ytPlayerShuffleIcon.active {
  color: #a4f10c;
}

.ytPlayerShuffleIcon.inactive {
  color: gray;
}

#yt-player {
  display: block;
}


.yt-player-class {
  width: 350px;
  height: 197px;
}

/* For width 400px and larger: */
@media only screen and (min-width: 900px) {
  .ytPlayerWrapper {
    top: 75px;
    bottom: auto;
  }

  .yt-player-class {
    width: 720px;
    height: 405px;
  }
}
